@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *::after,
  *::before {
    @apply m-0 p-0 box-border;
  }

  html,
  body {
    font-family: 'Roboto', sans-serif;
    @apply bg-gray-50 w-full h-full overflow-x-hidden;
  }

  ::-webkit-scrollbar {
    @apply w-2;
  }

  ::-webkit-scrollbar-track {
    @apply bg-white;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-secondary-700;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-secondary-600;
  }

  input[type='checkbox'] {
    @apply accent-primary-500 scale-[1.1];
  }
}

@layer components {
  .btn {
    @apply rounded py-2 px-8 cursor-pointer text-base duration-300 shadow disabled:cursor-not-allowed flex items-center justify-center text-center;
  }

  .btn-dark {
    @apply bg-dark-500 text-white hover:bg-dark-400 disabled:bg-dark-300;
  }

  .btn-primary {
    @apply bg-primary-500 text-white hover:bg-primary-400 disabled:bg-primary-300;
  }

  .btn-warning {
    @apply bg-yellow-500 text-white hover:bg-yellow-400 disabled:bg-yellow-300;
  }

  .btn-outline {
    @apply border-[1px] border-primary-500 hover:bg-primary-500 bg-white hover:text-white !important;
  }

  .btn-sm {
    @apply text-sm py-1 px-6;
  }

  .btn-xm {
    @apply text-[0.8rem] py-[0.12rem] px-4;
  }

  .form {
    @apply shadow p-2 md:p-6 rounded flex flex-col items-center justify-center gap-2 bg-white w-full md:w-fit h-auto border-[0.1px] border-dark-100;
  }

  .formControl {
    @apply w-full flex flex-col items-start justify-center;
  }

  .formInput {
    @apply w-full border-[0.1px] border-secondary-300 rounded py-1 px-2 outline-[0.1px] focus:outline-dark-500;
  }

  .formLabel {
    @apply text-sm;
  }

  .title {
    @apply font-bold text-secondary-700 text-3xl md:text-5xl;
  }

  .subTitle {
    @apply font-bold text-secondary-700 text-3xl;
  }

  .alert {
    @apply rounded py-2 px-3 text-sm md:text-base md:py-3 md:px-6 font-medium shadow-sm flex items-center gap-2;
  }

  .alertDanger {
    @apply bg-primary-200 text-primary-700;
  }

  .alertSuccess {
    @apply bg-green-200 text-green-700;
  }

  .alertInfo {
    @apply bg-blue-200 text-blue-700;
  }
}

@layer utilities {
  .container {
    @apply w-full md:max-w-7xl px-2 sm:px-4 md:px-16 py-4 md:py-8;
  }

  .modalContainer {
    @apply bg-white rounded self-center fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[95%] md:max-w-[500px] flex flex-col gap-2 max-h-[90vh];
  }

  .modalHeader {
    @apply bg-primary-500 p-2 md:p-4 rounded-t flex justify-between items-center;
  }

  .modalBody {
    @apply py-2 px-2 md:px-4 overflow-y-auto;
  }

  .modalFooter {
    @apply flex items-center justify-between py-2 px-2 md:px-4 border-[0.1px] border-secondary-100 rounded-b gap-2;
  }

  .flexCenter {
    @apply flex items-center justify-center;
  }

  .flexBetween {
    @apply flex items-center justify-between;
  }

  .minHeightCalc {
    @apply min-h-[calc(100vh-268px)];
  }

  .inputErrorMessage {
    @apply text-[0.8rem] text-primary-500;
    min-height: 20px;
  }

  .link {
    @apply text-primary-500 duration-300 hover:underline;
  }
}

.Toastify__toast {
  border: 0.1px solid #d6d6d6 !important;
}

.fade-in {
  animation: fadeIn 500ms;
  transform-origin: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 500ms;
  animation-fill-mode: forwards;
}

@keyframes slideUp {
  from {
    margin-top: 10%;
  }
  to {
    margin-top: 0;
  }
}

.radialBackground {
  background: rgb(35, 35, 35);
  background: radial-gradient(circle, rgba(25, 25, 25, 1) 0%, rgba(65, 65, 65, 1) 100%);
}

.loader {
  border: solid #eb322b;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
